import bsCustomFileInput from "bs-custom-file-input";

const $ = require('jquery');

var countOfFilesToLoad;
var waitImgDone = function() {
    countOfFilesToLoad--;
    if (!countOfFilesToLoad) pairs.makePairs();
};

function readFiles(input, appendDivName) {
    //Get the files
    let files = input.files || [];

    if (files.length) {

        countOfFilesToLoad = files.length;

        let nextOrder = $('#' + appendDivName + ' div.img-div').length + 1;
        for (let index = 0; index < files.length; index++) {
            //instantiate a FileReader for the current file to read
            let reader = new FileReader();

            reader.onload = function(e) {

                let div;
                div = $('<div class="img-new img-div">');

                const image = new Image();
                image.src = e.target.result;

                image.onload = function () {
                    div.prepend('<p class="img-resolution">' + this.width + ' x ' + this.height + '</p>');
                    waitImgDone();
                };

                let nTag = $('<i class="top-right-icon new-i far fa-plus-square"></i>');
                let atag = $('<a class="select-mi" href="#" data-img-name="' + files[index].name + '" data-img-order="' + nextOrder + '"></a>');
                nextOrder = nextOrder + 1;

                let img = $('<img src="#" alt="your image" class="img-thumbnail rounded img-admin"/>');
                img.attr('src', e.target.result);

                atag.append(img);
                atag.click(function(e) {
                    e.preventDefault();
                    mainImageSelected($(this));
                });

                div.append(atag);
                div.append(nTag);
                div.append(getOrderArrows());

                $('#' + appendDivName).append(div);

                if ($('.select-mi').length === 1) {
                    mainImageSelected(atag);
                }
            };
            reader.readAsDataURL(files[index]);
        }
    }
}

function moveImg(trg, dir) {
    let toMove, target, index, parent, children;

    toMove = $(trg).offsetParent();
    parent = toMove.parent();
    children = toMove.parent().children('div.img-div');
    index = parseInt($(toMove.children('a.select-mi')).data('img-order'));

    if ('left' === dir && index > 1) {
        moveImgLeft(toMove.get(0), parent, index);
    } else if ('right' === dir && index < children.length) {
        moveImgRight(toMove.get(0), parent, index);
    }

    resetOrderIndexes();
}

function moveImgLeft(toMove, parent, index) {
    const children = parent.children('div.img-div');
    let targetIndex = index - 2;

    let target = children.get(targetIndex);
    if (pairs.isPaired($(target))) {
        targetIndex -= 1;
    }

    let toMoveObjects = [];
    toMoveObjects.push(toMove);

    if (pairs.isPaired($(toMove))) {
        toMoveObjects.push(children.get(index));
    }

    $.each(toMoveObjects, function() {
        parent.children('div.img-div').get(targetIndex).before(this);
        targetIndex++;
    });
}

function moveImgRight(toMove, parent, index) {
    const children = parent.children('div.img-div');
    let targetIndex = index;

    let target = children.get(targetIndex);
    if (pairs.isPaired($(target))) {
        targetIndex += 1;
    }

    let toMoveObjects = [];
    toMoveObjects.push(toMove);

    if (pairs.isPaired($(toMove))) {
        toMoveObjects.push(children.get(index - 2));
    }

    $.each(toMoveObjects, function() {
        parent.children('div.img-div').get(targetIndex).after(this);
        targetIndex--;
    });
}

function resetOrderIndexes() {
    let i = 1, orderCount;
    for(i; i < 3; i++) {
        orderCount = 1;
        $('#category' + i + ' div.img-div a.select-mi').each(function() {
            $(this).data('img-order', orderCount);
            orderCount++;
        });
    }
}

function getOrderArrows() {
    let div = $('<div></div>');

    let dirs = ['left', 'right'];
    let aTags = [];

    let dir;

    for (let index = 0; index < dirs.length; index++) {
        dir = dirs[index];
        let aTag = $('<a href="#"><i class="order-' + dir + '-i fas fa-long-arrow-alt-' + dir + '"></i></a>');
        aTag.click(function(e) {
            e.preventDefault();
            moveImg(this, dirs[index]);
            pairs.makePairs();
        });
        aTags.push(aTag);
    }

    return div.append(aTags);
}

function mainImageSelected(el) {
    $('.select-mi').children().removeClass('selected-mi');
    el.children().addClass('selected-mi');
    $('#model_main_image_name').val(el.data('img-name'));

    const imgDiv = el[0].parentNode;
    $(imgDiv).removeClass('is-paired-left');
    $(imgDiv).removeClass('is-paired-right');

    const imgIndex = el.data('img-order');
    const imgCategory = imgDiv.parentNode;

    const prev = $(imgCategory).find('a.select-mi[data-img-order="' + (imgIndex - 1) + '"]');
    if (0 !== prev.length) {
        $(prev[0].parentNode).removeClass('is-paired-left')
    }

    const next = $(imgCategory).find('a.select-mi[data-img-order="' + (imgIndex + 1) + '"]');
    if (0 !== next.length) {
        $(next[0].parentNode).removeClass('is-paired-right');
    }

    pairs.makePairs();
}

function highlightMainImage() {
    $('[data-img-name="' + $('#model_main_image_name').val() + '"]').children().addClass('selected-mi');
}

function removeImage(el, addToLater) {
    if (addToLater === true) {
        let ril = $('#model_removeImgLater');
        if (ril.val().length) {
            ril.val(ril.val() + ',' + el.data('img'));
        } else {
            ril.val(el.data('img'));
        }
    }
    let parent = el.parent($('.img-div'));
    let mainImageName = $('#model_main_image_name');
    if (parent.children($('.select-mi')).data('img-name') === mainImageName.val()) {
        mainImageName.val('');
    }
    parent.remove();
}

const pairs =
{
    linkClass: 'pair-i',

    init: function()
    {
        this.initSavedPairs();
        this.makePairs();
    },

    initSavedPairs: function()
    {
        const savedImgPairs = $('#model_savedImgPairs');

        if (0 !== savedImgPairs.length) {
            const pairs = savedImgPairs.val().split(";;");
            $.each(pairs, function(index, name) {
                const el = $('a.select-mi[data-img-name="' + name + '"]');

                if (0 !== el.length) {
                    $(el[0].parentNode).addClass('is-paired-left');
                }
            });

            let i, nextIsRight = false;
            for(i = 1; i < 3; i++) {
                $('#category' + i + ' div.is-paired-left').each(function() {
                    if (nextIsRight) {
                        $(this).removeClass('is-paired-left');
                        $(this).addClass('is-paired-right');
                        nextIsRight = false;
                    } else {
                        nextIsRight = true;
                    }
                });
            }
        }
    },

    makePairs: function()
    {
        $('.' + this.linkClass).remove();

        let i = 1;
        let arr = [];
        for (i; i < 3; i++) {
            arr = $('#category' + i + ' div.img-div');
            arr.each(function(j) {
                if (j !== (arr.length - 1) &&
                    !pairs.isMainImage($(arr[j]))) {
                    pairs.appendLink($(arr[j]));
                }
            });
        }
    },



    appendLink: function(el)
    {
        const link = $('<a class="' + this.linkClass + '" href="#"><i class="fas fa-link"></i></a>');
        this.addOnClickPair(link);

        const category = $(el[0].parentNode);
        const next = $(category.find('div.img-div')[this.getImgOrder(el)]);

        if (this.isPaired(el)) {
            if (this.isLeftPair(el)) {
                link.addClass('pair-i-paired');
                this.hidePairedOrderArrow(el, next);
            } else {
                link.hide();
            }
        } else {
            if (this.isPaired(next) || this.isMainImage(next)) {
                link.hide();
            }
            this.showPairedOrderArrow(el, next);
        }

        el.append(link);
    },

    addOnClickPair: function(el)
    {
        el.click(function(e) {

            const el = $(this);
            const parent = $(this.parentNode);
            const imgOrder = pairs.getImgOrder(parent);

            const category = $(this.parentNode.parentNode);
            const next = $(category.find('div.img-div')[imgOrder]);

            const prev = imgOrder >= 2 ? $(category.find('div.img-div')[imgOrder - 2]) : null;

            if (pairs.isPaired(parent)) {
                el.removeClass('pair-i-paired');
                parent.removeClass('is-paired-left');
                next.removeClass('is-paired-right');

                pairs.showAfterUnpaired(parent, next, prev);
            } else {
                el.addClass('pair-i-paired');
                parent.addClass('is-paired-left');
                next.addClass('is-paired-right');

                pairs.hideAfterPaired(parent, next, prev);
            }

            e.preventDefault();
        });
    },

    showPairedOrderArrow: function(current, next)
    {
        current.find('i.order-right-i').show();
        next.find('i.order-left-i').show();
    },

    showAfterUnpaired: function(current, next, prev)
    {
        this.showPairedOrderArrow(current, next);
        next.find('a.pair-i').show();

        if (null !== prev && !this.isMainImage(prev) && !this.isPaired(prev)) {
            prev.find('a.pair-i').show();
        }
    },

    hidePairedOrderArrow: function(current, next)
    {
        current.find('i.order-right-i').hide();
        next.find('i.order-left-i').hide();
    },

    hideAfterPaired: function(current, next, prev)
    {
        this.hidePairedOrderArrow(current, next);
        next.find('a.pair-i').hide();

        if (null !== prev && !this.isMainImage(prev) && !this.isPaired(prev)) {
            prev.find('a.pair-i').hide();
        }
    },

    isLeftPair: function(el)
    {
        return el.hasClass('is-paired-left');
    },

    isPaired: function(el)
    {
        return el.hasClass('is-paired-right') || el.hasClass('is-paired-left');
    },

    isMainImage: function(el)
    {
        return 0 !== el.find('img.selected-mi').length;
    },

    getImgOrder: function(el)
    {
        return parseInt(el.find('a.select-mi').data('img-order'));
    }
};

$(document).ready(function() {

    $('form[name="model"]').submit(function(e) {
        if ($('#model_main_image_name').val().length === 0 && $('#model_active').is(':checked')) {
            e.preventDefault();
            alert('Není vybrán hlavní obrázek');
        }
        resetOrderIndexes();
        addImgOrdersToForm();
        addImgPairsToForm();
    });

    function addImgOrdersToForm() {
        $("#images div.img-div a.select-mi").each(function() {
            $('<input>').attr({
                type: 'hidden',
                name: 'IMG_ORDER[]',
                value: $(this).data('img-name') + ';;' + $(this).data('img-order')
            }).appendTo('form[name="model"]');
        });
    }

    function addImgPairsToForm() {
        $("#images div.is-paired-left a.select-mi").each(function() {
            $('<input>').attr({
                type: 'hidden',
                name: 'IMG_PAIRS[]',
                value: $(this).data('img-name')
            }).appendTo('form[name="model"]');
        });

        $("#images div.is-paired-right a.select-mi").each(function() {
            $('<input>').attr({
                type: 'hidden',
                name: 'IMG_PAIRS[]',
                value: $(this).attr('data-img-name')
            }).appendTo('form[name="model"]');
        });
    }

    // vybirani priloh
    bsCustomFileInput.init();
    $('input[type="file"]').change(function(e){
        let appendDiv = this.id === 'model_images_port' ? 'category2' : 'category1';
        $('#' + appendDiv + ' .img-new').remove();
        readFiles(this, appendDiv);
    });

    // potvrzeni pri mazani modela
    $('.confirm').click(function(e) {
        e.preventDefault();
        if (window.confirm("Are you sure?")) {
            location.href = this.href;
        }
    });

    // vybrani noveho hlavniho obrazku
    $('.select-mi').click(function(e) {
        e.preventDefault();
        mainImageSelected($(this));
    });

    // smazani obrazku a pridani do removeImgLater
    $('#model_removeImgLater').val('');
    $('.delete-i').click(function(e) {
        e.preventDefault();
        removeImage($(this), true);
    });

    // highlight vybraneho hlavniho obrazku
    highlightMainImage();

    // pridani img orderu
    $('#images div.img-div').append(getOrderArrows());

    pairs.init();
});